import React from "react";

const Price = ({ value, className }) => {
  if (isNaN(value)) return "";

  return <span className={className}>${parseFloat(value).toFixed(2)}</span>;
};

const formatPrice = (value) => {
  return "$" + parseFloat(value).toFixed(2);
};

const formatTax = (value) => {
  return "$" + parseFloat(value.toFixed(4)).toFixed(2);
};

const SupDollarSign = () => {
  return (
    <span
      className="inline-block text-[0.7em]"
      style={{
        transform: "translateY(-0.3em)",
      }}
    >
      $
    </span>
  );
};

const calculateProductTotal = (value) => {
  const { salePrice, basePrice, extraCost } = value;
  let priceInCents = salePrice
    ? Math.floor(salePrice * 100)
    : Math.floor(basePrice * 100);
  let extraCostInCents = Math.floor(extraCost * 100) || 0;
  let totalItemCostInDollars = (priceInCents + extraCostInCents) / 100;

  return totalItemCostInDollars;
};

export { formatPrice, formatTax, calculateProductTotal, SupDollarSign, Price };
