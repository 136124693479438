import axios from "axios";
import { endOfToday } from "date-fns";
import { graphql, navigate, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { zipCodes } from "../data/zip-codes";
import { formatPrice, formatTax } from "../utilities/price";

export const SHIPPING_PRICES = {
  "Denver Covers": [70, 90, 140],
  "Utah Covers": [60, 80, 100],
};

const COMPANY_NAME_SHORT = process.env.COMPANY_NAME_SHORT;

const defaultState = {
  checkoutInfo: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    shippingZip: "",
    whichWells: "",
    whichWellsNotes: "",
    shippingAddress: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingNotes: "",
    billingFirstName: "",
    billingLastName: "",
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingState: process.env.BILLING_STATE,
    billingZip: "",
    billingNotes: "",
    callInfo: null,
  },
  contents: [],
  totals: {
    countItems: 0,
    subtotal: 0,
    shipping: 0,
    tax: 0,
    total: 0,
    discount: 0,
    countOfCoversInCart: 0,
    countOfLinersInCart: 0,
  },
  discounts: {
    amount: 0,
    percent: 0,
  },
  discount: {},
  addToCartRedirect: "",
  cartDrawerOpen: false,
  recommendedProducts: () => {},
  accessoryProducts: () => {},
  addItemToCart: () => {},
  checkIfCartHasItemByUrl: () => {},
  getInstallationCost: () => {},
  removeItemFromCart: () => {},
  resetCart: () => {},
  setItemQuantity: () => {},
  setDiscountCode: () => {},
  setDiscount: () => {},
  getItemDiscount: () => {},
  getShipping: () => {},
  getShippingCounty: () => {},
  setReferenceOrder: () => {},
  getReferenceOrder: () => {},
  setCartRedirect: () => {},
  getCartRedirect: () => {},
  setCheckoutInfo: () => {},
  getCheckoutInfo: () => {},
  setCartDrawerOpenStatus: () => {},
};

const defaultDiscount = {
  amount: 0,
  percent: 0,
  expiration: new Date(),
};

const CartContext = React.createContext(defaultState);

const CartProvider = ({ children }) => {
  const [contents, setContents] = useState([]);
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [totals, setTotals] = useState({});
  const [discounts, setDiscounts] = useState({});
  const [lastEdited, setLastEdited] = useState(null);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  // save localStorage values
  useEffect(() => {
    if (!Object.values(checkoutInfo).length && !contents.length) {
      return;
    }

    localStorage.setItem(
      "cartValues",
      JSON.stringify(
        {
          checkoutInfo,
          contents,
        },
        null,
        2
      )
    );
  }, [checkoutInfo, contents]);

  // load cart values from localStorage
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const cartValues = localStorage.getItem("cartValues")
        ? JSON.parse(localStorage.getItem("cartValues"))
        : {
            checkoutInfo: {},
            contents: [],
            lastEdited: new Date(),
          };

      cartValues.contents.sort((a, b) => {
        if (a.basePrice > b.basePrice) {
          return -1;
        }
        if (a.basePrice < b.basePrice) {
          return 1;
        }
        return 0;
      });

      setContents(cartValues.contents);
      setCheckoutInfo(cartValues.checkoutInfo);
      setLastEdited(cartValues.lastEdited);
    }
  }, []);

  useEffect(() => {
    if (!contents) {
      return;
    }

    if (!contents.find((item) => item.discount)) {
      return setDiscounts(defaultDiscount);
    }

    contents.map((item) => {
      if (item.discount) {
        setDiscounts({
          name: `$${item.discount}`,
          code: item.id,
          cart_or_item_discount: "item",
          percent_or_amount_off: "amount",
          amount: item.discount,
        });
      }
    });
  }, [contents]);

  // calculate totals
  useEffect(() => {
    const getCartDiscount = (discountableProducts) => {
      if (discountableProducts.length === 0) {
        return 0;
      }

      if (
        discounts.percent_or_amount_off === "percent" &&
        discounts.percent > 0
      ) {
        return discounts.percent
          ? discountableProducts.reduce((accumulator, currentValue) => {
              let price = currentValue.salePrice
                ? currentValue.salePrice
                : currentValue.basePrice;

              const itemDiscount =
                +currentValue.quantity * +price * (+discounts.percent / 100);

              return +accumulator + itemDiscount;
            }, 0)
          : discounts.amount;
      }

      if (
        discounts.percent_or_amount_off === "amount" &&
        discounts.amount > 0
      ) {
        if (discounts.cart_or_item_discount === "cart") {
          return discounts.amount;
        }

        const quantityOfDiscountableProducts = discountableProducts.reduce(
          (accumulator, currentValue) => {
            return +currentValue.quantity + accumulator;
          },
          0
        );

        return quantityOfDiscountableProducts * discounts.amount;
      }
    };

    if (contents.length) {
      const countItems = contents.reduce((accumulator, currentValue) => {
        return +accumulator + +currentValue.quantity;
      }, 0);
      let subtotal = contents.reduce((accumulator, currentValue) => {
        let extraCostInCents = currentValue.extraCost
          ? Math.floor(currentValue.extraCost * 100)
          : 0;
        let priceInCents = currentValue.salePrice
          ? Math.floor(currentValue.salePrice * 100)
          : Math.floor(currentValue.basePrice * 100);
        let priceInDollars = priceInCents / 100;
        let extraCostInDollars = extraCostInCents / 100;
        let price = priceInDollars + extraCostInDollars;
        return +accumulator + +price * +currentValue.quantity;
      }, 0);

      const discountableProducts = contents.filter((product) => {
        return product.id === discounts.code;
      });

      const discount = getCartDiscount(discountableProducts);

      let tax = (subtotal - discount) * +process.env.TAX_RATE;
      const shipping = getShipping(checkoutInfo.shippingZip);
      const installation = getInstallationCost();

      let total = subtotal - discount + tax + shipping + installation;

      setTotals({
        countItems,
        subtotal: formatPrice(subtotal + shipping + installation),
        tax: formatTax(tax),
        total: formatPrice(Math.round((total + Number.EPSILON) * 100) / 100),
        discount: formatPrice(discount || 0),
        shipping: formatPrice(shipping),
        installation: formatPrice(installation),
        countOfLinersInCart: getLinersCount(),
        countOfCoversInCart: getCoversCount(),
      });
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents, checkoutInfo, discounts]);

  const accessoryProductsQuery = useStaticQuery(graphql`
    query GetAccessoryProductQuery {
      allWpProduct {
        edges {
          node {
            id
            title
            slug
            status
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            product {
              mainH1
              basePrice
              salePrice
              sku
              taxable
              category
              type
              imageGallery {
                title
                localFile {
                  publicURL
                }
              }
              cartCalloutText
            }
            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (lastEdited) {
      // if the cart was last edited over 12 hours ago clear it
      if (lastEdited <= +new Date() - 43200000) {
        resetCart();
      }
    }
  }, [lastEdited]);

  useEffect(() => {
    if (sessionStorage.getItem("discounts")) {
      setDiscount(JSON.parse(sessionStorage.getItem("discounts")));
      setDiscounts(JSON.parse(sessionStorage.getItem("discounts")));
    }
  }, []);

  const getCoversCount = () => {
    return contents
      .filter((product) => {
        return product.title.includes("Cover");
      })
      .reduce((prevValue, currentValue) => {
        return +currentValue.quantity + +prevValue;
      }, 0);
  };

  const getLinersCount = () => {
    return contents
      .filter((product) => {
        return product.title.includes("Liner");
      })
      .reduce((prevValue, currentValue) => {
        return +currentValue.quantity + +prevValue;
      }, 0);
  };

  // methodOverride is used to estimate shipping costs before selection
  const getInstallationCost = (methodOverride) => {
    const method = methodOverride || checkoutInfo.shippingMethod;

    if (method === "pickup" || method === "delivery") {
      return 0;
    }

    const countOfLinersInCart = getLinersCount();
    if (countOfLinersInCart > 0) {
      return 75 * countOfLinersInCart + 30;
    }

    return 30;
  };

  const recommendedProducts = () => {
    return accessoryProducts().filter((product) => {
      if (
        !product.node.product.basePrice ||
        checkIfCartHasItemByUrl(`/${product.node.slug}/`)
      ) {
        return false;
      }
      return true;
    });
  };

  const accessoryProducts = () => {
    return accessoryProductsQuery.allWpProduct.edges
      .filter((product) => {
        return product.node.product.category.includes("accessories");
      })
      .sort((productA, productB) =>
        +productA.node.product.basePrice < +productB.node.product.basePrice
          ? 1
          : -1
      );
  };

  const resetCart = () => {
    setLastEdited(+new Date());
    setContents([]);
    setCheckoutInfo({});
    setDiscounts(defaultDiscount);

    localStorage.setItem(
      "cartValues",
      JSON.stringify(
        {
          checkoutInfo: {},
          contents: [],
        },
        null,
        2
      )
    );
  };

  const setShippingMethod = (name) => {
    // change shipping method
    setCheckoutInfo((ci) => ({ ...ci, shippingMethod: name }));
  };

  const setItemQuantity = (id, quantity) => {
    if (!quantity) {
      quantity = 1;
    }

    const itemInCart = contents.filter((value) => {
      if (value.id === id) {
        return value;
      }
      return false;
    });
    if (itemInCart.length) {
      setContents(
        contents.map((value) => {
          if (value.id === id) {
            value.quantity = +quantity;
          }
          return value;
        })
      );
    }
  };

  // methodOverride is used to estimate shipping costs before selection
  const getShipping = (shippingZip, methodOverride) => {
    const method = methodOverride || checkoutInfo.shippingMethod;

    if (method === "pickup") return 0;
    if (getLinersCount() > 0) return 0;

    if (zipCodes[0].zipCodes.indexOf(+shippingZip) > -1) {
      return SHIPPING_PRICES[COMPANY_NAME_SHORT][0];
    }
    if (zipCodes[1].zipCodes.indexOf(+shippingZip) > -1) {
      return SHIPPING_PRICES[COMPANY_NAME_SHORT][1];
    }
    return SHIPPING_PRICES[COMPANY_NAME_SHORT][2];
  };

  const getShippingCounty = (shippingZip) => {
    if (zipCodes[0].zipCodes.indexOf(+shippingZip) > -1)
      return `${zipCodes[0].label}`;
    if (zipCodes[1].zipCodes.indexOf(+shippingZip) > -1)
      return `${zipCodes[1].label}`;
    if (zipCodes[2].zipCodes.indexOf(+shippingZip) > -1)
      return `${zipCodes[2].label}`;
    return "";
  };

  const addItemToCart = (item, goToCart = true) => {
    setContents((c) => {
      const itemInCart = c.find((value) => {
        if (value.id === item.id) {
          return item;
        }
        return false;
      });
      if (itemInCart) {
        const newArr = c.map((value) => {
          if (value.id === item.id) {
            value.quantity = +value.quantity + +item.quantity;
          }
          return value;
        });
        const indexToMove = newArr.findIndex((obj) => obj.id === item.id);

        if (indexToMove !== -1) {
          const objectToMove = newArr.splice(indexToMove, 1)[0];
          newArr.unshift(objectToMove);
        }

        return newArr;
      } else {
        return [item, ...c];
      }
    });

    if (
      typeof window !== "undefined" &&
      window.location.pathname !== "/cart/" &&
      getCartRedirect()
    ) {
      navigate(getCartRedirect());
    } else if (
      typeof window !== "undefined" &&
      window.location.pathname !== "/cart/" &&
      goToCart
    ) {
      navigate("/cart/");
    }
  };

  const checkIfCartHasItemByUrl = (url = "") => {
    if (!contents.length) {
      return false;
    }
    return contents.filter((value) => {
      return value.url === url;
    }).length;
  };

  const removeItemFromCart = (id) => {
    const newContents = contents.filter((value) => {
      if (value.id !== id) {
        return value;
      }
      return false;
    });
    setContents(newContents);
  };

  const setDiscountCode = async (code, notify = true) => {
    if (code === "") {
      setDiscounts(defaultDiscount);
      return;
    }

    if (code === discounts?.code) {
      return;
    }

    const response = await axios.get(
      `${process.env.DATA_API}/wp-json/${process.env.COMPANY_NAME_SLUG}/v1/discounts/?code=${code}`
    );

    if (response.status === 200) {
      if (response.data.length) {
        if (new Date() > new Date(response.data[0].expiration)) {
          if (notify) {
            toast.error("That offer has expired");
          }
        } else {
          setDiscount({
            name: response.data[0].name,
            code: response.data[0].code,
            cart_or_item_discount: response.data[0].cart_or_item_discount,
            percent_or_amount_off: response.data[0].percent_or_amount_off,
            amount: response.data[0].amount_off,
            percent: response.data[0].percent_off,
            categories: response.data[0].categories,
            expiration: new Date(response.data[0].expiration),
          });
          setCheckoutInfo((c) => ({
            ...c,
            discountCode: response.data[0].code,
          }));
          if (notify) {
            toast.success("Discount applied");
          }
          return response.data[0];
        }
      } else {
        if (notify) {
          toast.info("The discount code you entered is not valid");
        }
      }
    } else {
      console.error(response);
    }
    return [];
  };

  const setDiscount = (discount) => {
    if (discount === "allforthetest") {
      const discountData = {
        name: "All For The Test",
        code: "All For The Test",
        cart_or_item_discount: "cart",
        percent_or_amount_off: "percent",
        amount: 0,
        percent: 100,
        expiration: endOfToday(),
        categories: ["accessories", "covers"],
      };
      setDiscounts({
        discounts: discountData,
      });
      sessionStorage.setItem("discounts", JSON.stringify(discountData));
      return;
    }

    if (discount.amount || discount.percent) {
      const discountData = {
        name: discount.name ? discount.name : "",
        code: discount.code ? discount.code : "",
        amount:
          discount.percent_or_amount_off === "amount" && discount.amount
            ? +discount.amount
            : 0,
        percent:
          discount.percent_or_amount_off === "percent" && discount.percent
            ? +discount.percent
            : 0,
        expiration: discount.expiration ? +discount.expiration : 0,
        categories: discount.categories ? discount.categories : [],
        cart_or_item_discount: discount.cart_or_item_discount
          ? discount.cart_or_item_discount
          : "cart",
        percent_or_amount_off: discount.percent_or_amount_off
          ? discount.percent_or_amount_off
          : "percent",
      };
      setDiscounts(discountData);
      sessionStorage.setItem("discounts", JSON.stringify(discountData));
      return;
    } else {
      setDiscounts(defaultDiscount);
    }
  };

  const getItemDiscount = (item) => {
    // if there is no discount return item price
    if (!discounts.percent && !discounts.amount) {
      return item.salePrice ? item.salePrice : item.basePrice;
    }

    // check applicable categories
    if (
      discounts.categories.length &&
      !discounts.categories.filter((value) => item.category.includes(value))
        .length
    ) {
      return item.salePrice ? item.salePrice : item.basePrice;
    }

    if (discounts.percent) {
      return item.salePrice
        ? item.salePrice * ((100 - discounts.percent) / 100)
        : item.basePrice * ((100 - discounts.percent) / 100);
    }

    return item.salePrice ? item.salePrice : item.basePrice;
  };

  const setReferenceOrder = (order) => {
    return sessionStorage.setItem("reference_order", order);
  };

  const getReferenceOrder = () => {
    return sessionStorage.getItem("reference_order")
      ? sessionStorage.getItem("reference_order")
      : "";
  };

  const getCartRedirect = () => {
    return sessionStorage.getItem("cart_redirect")
      ? sessionStorage.getItem("cart_redirect")
      : "";
  };

  const setCartRedirect = (path = "") => {
    return sessionStorage.setItem("cart_redirect", path);
  };

  const setCartDrawerOpenStatus = (status) => {
    return setCartDrawerOpen(status);
  };

  const setOrderRequiresNoDeposit = () => {
    return sessionStorage.setItem(
      "order_requires_no_deposit",
      "0c7adabb60059d12d29c6ecdfac43c40"
    );
  };

  return (
    <CartContext.Provider
      value={{
        accessoryProducts: accessoryProducts,
        addItemToCart: addItemToCart,
        checkIfCartHasItemByUrl: checkIfCartHasItemByUrl,
        cartDrawerOpen: cartDrawerOpen,
        checkoutInfo,
        contents,
        discounts,
        getCartRedirect: getCartRedirect,
        getInstallationCost: getInstallationCost,
        getItemDiscount: getItemDiscount,
        getReferenceOrder: getReferenceOrder,
        getShipping: getShipping,
        getShippingCounty: getShippingCounty,
        recommendedProducts: recommendedProducts,
        removeItemFromCart: removeItemFromCart,
        resetCart: resetCart,
        setCartRedirect: setCartRedirect,
        setCheckoutInfo: setCheckoutInfo,
        setDiscountCode: setDiscountCode,
        setDiscount: setDiscount,
        setItemQuantity: setItemQuantity,
        setOrderRequiresNoDeposit: setOrderRequiresNoDeposit,
        setReferenceOrder: setReferenceOrder,
        setShippingMethod: setShippingMethod,
        setCartDrawerOpenStatus: setCartDrawerOpenStatus,
        totals,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartContext.propTypes = {
  contents: PropTypes.array,
  checkoutInfo: PropTypes.object,
};
export default CartContext;

export { CartProvider };
